import {required, email, integer, numeric, decimal} from 'vuelidate/lib/validators'

// https://github.com/marina-mosti/dynamic-schema-vuelidate


export const validationRules = schema => {

  const rules = {}

  for (let name in schema) {

    const item = schema[name]

    if (!item.hasOwnProperty('validations')) return

    const validations = {}

    for (let rule in item.validations) {

      const validation =  validationFn(rule)

      if (validation) {
        validations[rule] = validation
      }

    }

    rules[name] = validations

  }

  return rules
}

const validationFn = (name) => {
  switch (name) {
    case 'required':
      return required
    case 'email':
      return email
    case 'integer':
      return integer
    case 'decimal':
      //return numeric
  }
}
