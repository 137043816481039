<template>
  <div class="animated fadeIn" v-if="loaded">

  <form @submit.prevent="submitForm" novalidate>
  <b-row>
    <b-col cols="12" lg="12">

      <div class="card w-100">

        <div class="card-header">

          <span class="mt-2">Edit Rate : {{ table_name }}</span>
          <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>

        </div>

        <div class="card-body">

          <div id="error" class="card border-danger" v-show="error">
            <div class="card-header bg-danger border-danger">

                <span class="text-white font-weight-bolder">
                <i class="fa fa-exclamation-triangle"></i>
                 &nbsp;
                {{ error_heading }}
                </span>

            </div>

            <div class="card-body border-danger text-dark font-weight-bold">

              {{ error_text }}

            </div>
          </div>

          <div class="input" v-for="(field, key) in schema" :key="key">

            <label :for="field.name">{{ field.label }}</label>
            <b-input class="mb-2 mr-sm-2 mb-sm-0" v-if="field.type === 'number'"
              :state="!$v.form[key].$error"
              v-model.number.lazy="$v.form[key].$model"
            ></b-input>
            <b-input class="mb-2 mr-sm-2 mb-sm-0" v-else
               :type="field.type"
              :state="validateState(key)"
              v-model.lazy="$v.form[key].$model"
            ></b-input>
            <b-form-invalid-feedback class="error">{{ errorMessages[key] }}</b-form-invalid-feedback>

          </div>

        </div>

        <div class="card-footer">

          <b-button @click="goBack" class="float-left">Back</b-button>
          <b-button variant="success" type="submit" class="float-right">Edit Rate</b-button>

        </div>

      </div>
    </b-col>
  </b-row>
  </form>
  </div>
</template>

<script>
import { validationRules } from '@/form/parsers/ValidationParser'
import axios from '../../../shared/axios/auth-no-loader'
import {validationMixin} from "vuelidate";

export default {
  name: 'RateEdit',
  mixin: [validationMixin],
  data () {
    return {
      error: false,
      error_heading: '',
      error_text: '',
      table_name: null,
      loaded: false,
      schema: {},
      form: {},
      errors: {},
      status: null
    }
  },
  computed: {
   errorMessages () {

      const messages = {};

      for (let name in this.schema) {

        const item = this.schema[name]

        if (item.hasOwnProperty('validations')) {

          const rules = item.validations
          const rulesKeys = Object.keys(rules)

          if (this.$v.form[name].$error) {

            for (const rule of rulesKeys) {

              if (!this.$v.form[name].$model) {
                console.log('here')
                messages[name] = rules['required'].message
              } else if (this.$v.form[name][rule] && !this.$v.form[name][rule]) {
                console.log('here 1 :', name, ' - ', rule, ' : ', this.$v.form[name][rule], " ; ", !this.$v.form[name][rule])
                messages[name] = rules[rule].message
              }

            }

          }

        if (!this.$v.form[name].$model) {

          if (this.errors[name]) {
            messages[name] = this.errors[name]
          }
        }

        }

      }

      return messages

    }
  },
  methods: {
    validateState(name) {
      if (this.$v.form[name]) {
        const {$dirty, $error} = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    submitForm () {
      this.error = false;

      this.$v.$touch();
      this.$v.form.$touch();

      if (this.$v.$anyError && Boolean(this.errorMessages)) {

        window.scrollTo(document.querySelector('#error').offsetTop, 100);
        this.error = true;
        this.error_heading = 'There are errors on the form';
        this.error_text = 'Please fix the errors and re-submit the form';

        return

      } else {

        const post_data = this.form;

        console.log(post_data)

        let url = '';

        url = '/rates/' + this.$route.params.rateid + '/rate/?product=' + this.$route.params.product + '&insurer=' + this.$route.params.insurer

        axios.put(url, post_data).then(
          response => (
            this.data = response.data
          )
        ).then(response => {
          response

          let alert = {
            type: 'success',
            text: 'Rate was updated successfully!'
          };

          this.$store.commit(
            'setGlobalAlert',
            alert
          )
          this.$router.replace({name: 'TableRate',
            params: {
              product: this.$route.params.product,
              insurer: this.$route.params.insurer,
              id: this.$route.params.id,
              rateid: this.$route.params.rateid
            }
          })

        }).catch(error => {

          // Unauthorised
          if (error.response.status === 401) {
            this.$store.dispatch('logout');
          }

          if (error.response.status === 400) {

            window.scrollTo(document.querySelector('#error').offsetTop, 100);
            this.error = true;
            this.error_heading = 'There are errors on the form';
            this.error_text = 'Please fix the errors and re-submit the form';

            console.log(error.response.data)
            if (error.response.data) {

              for (var key in error.response.data) {
                this.errors[key] = error.response.data[key][0]
              }
              this.$v.$reset();
              this.$v.$touch();

            }

          }

          console.log('premium error');
          console.log(error.response)
        });
      }

    },
    get() {
        let url = '';

        url = '/rates/'+ this.$route.params.rateid + '/rate/?product=' + this.$route.params.product + '&insurer=' + this.$route.params.insurer;

        axios.get(url).then(
          response => {

            this.table_name = response.data.table_name
            this.schema = response.data.form_schema

            for (var field in this.schema) {
              this.form[field] = this.schema[field].value
            }

          }
        ).catch(error => {
            // Unauthorised

            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }
            console.log('premium error');
            console.log(error.response)
          })
        return this.form
      },
    },
    created () {
      this.get()
      this.loaded = true
      this.$options.validations()
    },
    validations () {
      return { form: validationRules(this.schema) }
    }
}
</script>
